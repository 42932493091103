import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostList from "../components/PostList";

export default function Template({ data, pageContext }) {
  const { name, slug } = pageContext;
  const posts = data.allMarkdownRemark.edges
    .map(edge => edge.node.frontmatter)
    .filter(p => p.categories && p.categories.includes(slug));
  return (
    <Layout>
      <SEO title={`Category: ${name}`} />
      <PostList posts={posts} title={`Category: ${name}`} />
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: 100000
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date
            categories
          }
        }
      }
    }
  }
`;
